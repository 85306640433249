<template>
  <v-card style="border-radius: 15px">
    <v-card-title class="text-h5 lighten-2">
      {{ item ? 'Editar usuario Scanner' : 'Agregar usuario scanner' }}
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
          snackbarText
        }}</v-snackbar>
        <v-row class="pa-4" style="margin: 0px">
          <v-col cols="12" md="12" sm="12">
            <v-text-field
              v-model="newItem.name"
              :rules="required"
              label="Nombre del Usuario"
              style="border-radius: 15px"
              required
              filled
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-text-field
              v-model="newItem.email"
              :rules="emailRules"
              required
              label="Correo"
              style="border-radius: 15px"
              filled
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <!-- :menu-props="{ maxHeight: '400' }" -->
            <!-- v-model="newItem.sectionsEvent" -->
            <v-select
              v-model="valueEvent"
              :items="events"
              label="Eventos"
              :rules="required"
              required
              multiple
              hint="Secciones los eventos"
              style="border-radius: 15px"
              persistent-hint
              filled
              item-text="name"
              :item-value="[`.key`]"
              rounded
            ></v-select>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <!-- :menu-props="{ maxHeight: '400' }" -->
            <v-select
              v-model="newItem.sectionsEvent"
              :items="getSections"
              label="Secciones"
              :rules="required"
              multiple
              required
              hint="Secciones que puede validar"
              style="border-radius: 15px"
              persistent-hint
              filled
              @change="getTicktesFromSections"
              rounded
            ></v-select>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <!-- :menu-props="{ maxHeight: '400' }" -->
            <v-select
              v-model="newItem.oViveTicketIds"
              :items="getTicktes"
              label="Entradas"
              :rules="required"
              multiple
              required
              hint="Entradas que puede validar"
              style="border-radius: 15px"
              persistent-hint
              filled
              rounded
            ></v-select>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <!-- :menu-props="{ maxHeight: '400' }" -->
            <v-select
              v-model="newItem.role"
              :items="roleType"
              label="Roles"
              :rules="required"
              required
              hint="Rol del usuario"
              style="border-radius: 15px"
              persistent-hint
              filled
              rounded
            ></v-select>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-text-field
              v-model="newItem.password"
              :rules="item ? [] : required"
              label="Contraseña"
              style="border-radius: 15px"
              required
              filled
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-combobox
              v-model="newItem.diloUsersId"
              :items="ids"
              chips
              clearable
              label="Lista de ID usuarios Dilo"
              multiple
              width="100%"
              filled
              rounded
              style="border-radius: 10px"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  small
                  outlined
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="12" md="12" sm="12">
            <v-switch v-model="newItem.active" label="Activo"> </v-switch>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        style="border-radius: 15px"
        @click="$emit('cancel')"
        :loading="loadbtn"
      >
        Cancelar
      </v-btn>
      <v-btn
        style="border-radius: 15px"
        color="primary"
        :loading="loadbtn"
        @click="addNewUser()"
      >
        aceptar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db, fb } from '@/firebase';
import moment from 'moment-timezone';
import 'moment/locale/es';
moment.locale('es');
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Add-User-Event',
  props: ['BusinessId', 'EventId', 'item'],
  //   components: {
  //     // lottie: Lottie,
  //   },
  data() {
    return {
      valid: true,
      snackbar: false,
      events: [],
      snackbarText: '',
      oViveTickets: [],
      allSections: [],
      defaulImage: require('@/assets/templateImage.svg'),
      loading: true,
      required: [(v) => !!v || 'Campo requerido'],
      emailRules: [
        (v) => !!v || 'Correo requerido',
        (v) => /.+@.+\..+/.test(v) || 'Correo invalido',
      ],
      loadbtn: false,
      valueEvent: null,
      ids: [],
      roleType: [
        {
          text: 'Productos',
          value: 'products',
        },
        {
          text: 'Tickets',
          value: 'tickets',
        },
        {
          text: 'Productos / Tickets',
          value: 'both',
        },
      ],
      newItem: {
        name: '',
        email: '',
        password: '',
        active: true,
      },
    };
  },
  computed: {
    ...mapState(['selectedEvent', 'user', 'business']),

    getSections() {
      return this.allSections.map((i) => {
        return {
          text: `${i.name} - (${i.eventName})`,
          value: i[`.key`],
        };
      });
    },
    getTicktes() {
      return this.oViveTickets.map((i) => {
        return {
          text: `${i.name}`,
          value: i[`.key`],
        };
      });
    },
  },
  watch: {
    async valueEvent(value) {
      console.debug(value);
      this.newItem.events = value;
      // this.newItem.sectionsEvent = null;
      var arraySections = [];
      for (let i = 0; i < value.length; i++) {
        const key = value[i];
        db.collection('events')
          .doc(key)
          .collection('sections')
          .where('business', '==', this.business[`.key`])
          .where('active', '==', true)
          .where('deleted', '==', false)
          .get()
          .then((response) => {
            response.forEach((x) => {
              var dataX = x.data();
              var event = this.events.find((s) => s[`.key`] == dataX.eventId);
              dataX[`.key`] = x.id;
              dataX.eventName = event.name;
              console.debug(dataX);

              arraySections.push(dataX);
            });
          });
      }
      this.allSections = arraySections;
    },
  },
  methods: {
    ...mapActions(['Alert_']),

    remove(item) {
      this.newItem.diloUsersId.splice(
        this.newItem.diloUsersId.indexOf(item),
        1
      );
    },
    async getTicktesFromSections(e) {
      // Obtener los tickets iniciales
      this.$binding(
        'oViveTickets',
        db
          .collection('oViveTickets')
          .where('section', 'in', e)
          .where('active', '==', true)
          .where('deleted', '==', false)
      );

      // Esperar a que se resuelva la consulta
      await this.$getBinding('oViveTickets');

      // Filtrar los tickets basados en eventos activos
      const filteredTickets = [];
      for (const ticket of this.oViveTickets) {
        const eventDoc = await db.collection('events').doc(ticket.event).get();
        if (eventDoc.exists && eventDoc.data().active === true) {
          filteredTickets.push(ticket);
        }
      }

      // Actualizar this.oViveTickets con los tickets filtrados
      this.oViveTickets = filteredTickets;

      console.log(this.oViveTickets);
    },
    addNewUser() {
      this.loadbtn = true;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        var userResult = fb
          .functions()
          .httpsCallable(this.item ? 'httpUsersV2Update' : 'httpUsersV2Create');
        // console.debug();

        const ObjUser = {
          name: this.newItem.name,
          email: this.newItem.email,
          password: this.newItem.password,
          sectionsEvent: this.newItem.sectionsEvent,
          active: this.newItem.active || false,
          deleted: false,
          businessId: this.BusinessId,
          oViveTicketIds: this.newItem.oViveTicketIds || [],
          events: this.valueEvent,
          diloUsersId: this.newItem.diloUsersId || [],
          role: this.newItem.role,
          type: 'viveScanner',
          uid: this.item ? this.item[`.key`] : null,
        };
        if (this.item) {
          ObjUser.newPassword = this.newItem.password;
        }

        console.debug('ObjUser', ObjUser);
        userResult(ObjUser)
          .then((result) => {
            this.$emit('success');
          })
          .catch((err) => {
            // console.debug(err);
            this.Alert_({
              text: 'Error al crear el usuario',
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: 'mdi-check',
              type: 'error',
            });
            this.loadbtn = false;
            // this.createUser = false;
          });
      } else {
        this.loadbtn = false;
        this.createUser = false;
        this.Alert_({
          text: 'Rellena todos los campos seleccionados',
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: 'mdi-check',
          type: 'error',
        });
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    validate() {
      return new Promise((resolve, reject) => {
        if (!this.link.customer) {
          this.snackbarText = 'Ingrese el nombre del cliente';
          this.snackbar = true;
          return resolve(false);
        }

        if (
          !this.link.amount ||
          isNaN(this.link.amount) ||
          this.link.amount <= 0
        ) {
          this.snackbarText = 'Ingrese un monto a cobrar válido.';
          this.snackbar = true;
          return resolve(false);
        }

        if (!this.link.description) {
          this.snackbarText = 'Ingrese una descripción del cobro';
          this.snackbar = true;
          return resolve(false);
        }

        return resolve(true);
      });
    },
  },
  async mounted() {
    this.loading = false;
    await this.$binding(
      'events',
      db
        .collection('events')
        .where('business', '==', this.business[`.key`])
        .where('active', '==', true)
        .where('deleted', '==', false)
    );
    if (this.item) {
      console.log(this.item);
      this.newItem = Object.assign({}, this.item);
      if (this.newItem.sectionsEvent.length) {
        this.getTicktesFromSections(this.newItem.sectionsEvent);
      }
      setTimeout(() => {
        console.debug(this.newItem.sectionsEvent);
        this.valueEvent = this.newItem.events;

        console.debug(this.newItem.events);
      }, 1000);
    }
  },
};
</script>
