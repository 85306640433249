<template>
  <div class="userScanner">
    <headerLayaut v-if="selectedEvent"
      :subtitle="`Usuarios escaner de boletos para evento ${selectedEvent.name}`"
    >
      <v-row justify="end">
        <v-btn
          style="border-radius: 15px"
          color="primary"
          elevation="0"
          @click="addUser"
          class="ma-2 white--text elevation-animations"
        >
          <v-icon right dark class="mr-3">fas fa-plus</v-icon>
          Crear nuevo usuario
        </v-btn>
      </v-row>
    </headerLayaut>
    <headerLayaut v-else
      subtitle="No hay evento seleccionado`"
    >
    </headerLayaut>

    <div class="body-template"  v-if="selectedEvent">
      <v-data-table
        :headers="headers"
        :items="allUsers"
        sort-by="createdAt"
        fixed-header
        :search="$store.state.search"
        :calculate-widths="true"
        :loading="loading"
        sort-desc
        item-key="id"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }"
      >
        <template v-slot:[`item.active`]="{ item }">
          <v-switch
            @change="switchControlChanged({ active: item.active }, item)"
            v-model="item.active"
            :loading="loading"
          ></v-switch>
        </template>
        <template v-slot:loading>
          <div class="ma-5" style="position: relative">
            <div class="lottie-wrapper">
              <v-progress-circular
                :width="3"
                color="#00cdbc"
                indeterminate
              ></v-progress-circular>
              <p
                :class="
                  $vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'
                "
              >
                Cargando Data
              </p>
            </div>
          </div>
        </template>
        <template v-slot:[`item.sectionsEvent`]="{ item }">
          <v-chip-group width="200px">
            <v-chip
              outlined
              small
              dense
              v-for="tag in item.sectionsNames"
              :key="tag"
            >
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </template>
        <template v-slot:[`item.options`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                style="border-radius: 10px"
                small
                :elevation="0"
                @click="editUser(item)"
                color="info"
              >
                <v-icon small dark>fa-solid fa-pen</v-icon>
              </v-btn>
            </template>
            <span>Editar {{ item.name }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <v-dialog
      v-model="addDialog"
      width="600"
      persistent
      scrollable
      v-if="addDialog"
      style="border-radius: 15px"
    >
      <new-user-event
        :BusinessId="business[`.key`]"
        :EventId="selectedEvent[`.key`]"
        :item="selectedItem"
        @cancel="addDialog = false"
        @success="handleSuccess"
      ></new-user-event>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store/index.js";
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import headerLayaut from "@/components/header-layaut.vue";
import moment from "moment-timezone";
import "moment/locale/es";
import newUserEvent from "@/components/add-user-event";

moment.locale("es");
export default {
  name: "userScanner",
  components: {
    newUserEvent,
    headerLayaut,
  },
  data() {
    return {
      addDialog: false,
      newItem: {},
      show1: false,
      loading: false,
      allUsers: [],
      allSections: [],
      allEvents: [],
      snackbarText: "",
      snackbar: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Correo",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Secciones",
          align: "start",
          sortable: true,
          value: "sectionsEvent",
        },
        {
          text: "Rol",
          align: "start",
          sortable: true,
          value: "role",
        },
        {
          text: "Activo",
          align: "start",
          sortable: true,
          value: "active",
        },
        {
          text: "",
          value: "options",
        },
      ],

      defaulImage: require("@/assets/templateImage.svg"),
    };
  },
  filters: {
    formatDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("lll A");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
    eventSerch(id) {
      var search = this.allSections.find((x) => id == x[`.key`]);
      return search ? search.name : "no disponible";
    },
  },
  computed: {
    filterNameSect() {},
    ...mapState(["user", "selectedEvent", "business"]),
    // type_fill() {
    //   var resul = this.type.filter((i) => i.type == this.data.paymentMethod);
    //   return resul[0].img;
    // },
    headerTable() {
      if (this.$store.state.user.type == "oplAdmin") {
        return this.headersSudo;
      }
      if (this.$store.state.user.type == "oplBusiness") {
        return this.headers;
      }
    },
  },
  async mounted() {
    this._searchTerm("usuarios");
    this.visibleSearch(true);

    await this.$binding(
      "allEvents",
      db
        .collection("events")
        .where("business", "==", this.business[`.key`])
        .where("active", "==", true)
        .where("deleted", "==", false)
    );
    this.allSections = [];

    for (let i = 0; i < this.allEvents.length; i++) {
      const element = this.allEvents[i];
      await db
        .collection("events")
        .doc(element[`.key`])
        .collection("sections")
        .where("business", "==", this.business[`.key`])
        .where("active", "==", true)
        .where("deleted", "==", false)
        .get()
        .then((response) => {
          console.debug(response);
          response.forEach((x) => {
            var datos = x.data();
            datos[`.key`] = x.id;
            this.allSections.push(datos);
          });
        });
    }

    if (this.user.type == "sudo") {
      this.GetallUser();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(["_searchTerm", "Alert_", "visibleSearch"]),

    editUser(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },

    handleSuccess() {
      this.Alert_({
        text: `El usuario fue ${
          this.selectedItem ? "actualizado" : "creado"
        } con éxito`,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: "success",
      });
      this.addDialog = false;
      this.selectedItem = null;
    },

    addUser() {
      this.selectedItem = null;
      this.addDialog = true;
    },

    switchControlChanged(data, item) {
      if (data && item) {
        this.loading = true;
        data.modifiedAt = new Date();

        db.collection("appUsers")
          .doc(item[`.key`])
          .update(data)
          .then((response) => {
            this.loading = false;

            this.Alert_({
              text: `El usuario fue ${
                data.active ? "activado" : "desactivado."
              } con éxito`,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "info",
            });
          })
          .catch((err) => {
            this.Alert_({
              text: err,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            item.active = !item.active;
            this.loading = false;
          });
      }
    },

    GetallUser() {
      this.tagsToSearchSection = "";
      this.$store.state.search = "";

      db.collection("appUsers")
        .where("type", "==", "viveScanner")
        .where("businessId", "==", this.business[`.key`])
        .onSnapshot((a) => {
          this.allUsers = [];

          a.docs.forEach((element) => {
            var datos = element.data();
            datos[`.key`] = element.id;

            if (datos.sectionsEvent.length > 0) {
              var arraySect = this.allSections.filter((x) =>
                datos.sectionsEvent.includes(x[`.key`])
              );
              datos.sectionsNames = arraySect.map((e) => {
                return e.name;
              });
            }

            // console.debug(datos);
            this.allUsers.push(datos);
          });
        });
      return this.allUsers;
    },
  },
};
</script>
<style lang="scss">
.dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;
  .wrap-login {
    width: 450px;
    height: 400px;
    background-color: white;
    border-radius: 1.5em !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }
}
.btn-primary-ocho {
  border-radius: 15px;
  box-shadow: 0px 4px 10px 5px rgba(255, 89, 0, 0.2);
}

.type-metod-img {
  border-radius: 10px;
}
.v-menu__content {
  border-radius: 15px !important;
}
</style>
